import React, { useState, useEffect } from "react";
import { useStore } from "../../../stores/index";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import { history } from "../../../history";
import { observer } from "mobx-react-lite";
import {
  initPaginateListPayloadGlobal,
  RemoveAuthToken,
} from "../../../utility/Common";
import Checkbox from "components/@vuexy/checkbox/CheckboxesVuexy";
import "./NavbarUser.scss";
import { DataLoader } from "../../../views/pages/_/DataLoader";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";

interface Props {
  userId: string;
  userName: string;
  userImage: string;
}

export const NavbarUser: React.FC<Props> = observer(
  ({ userId, userName, userImage }) => {
    const { t } = useTranslation();
    const {
      dataStore: { getTeams, TeamsListData },
      reportStore: {
        dashBoardReportMasterFilter,
        getDashBoardReportMasterFilter,
      },
      accountStore: {
        getUsersForFilters,
        usersForFiltersData,
        accountLogout,
        loading: {
          user: { listLoading },
        },
      },
      masterDataStore: { ipFaculties, getIpFaculties },
    } = useStore();
    useEffect(() => {
      if (userId) {
        getTeams(initPaginateListPayloadGlobal);
        getIpFaculties();
      }
    }, [userId]);
    const UserDropdown = () => {
      return (
        <DropdownMenu right>
          <DropdownItem
            tag="a"
            onClick={() => {
              history.push("/profile");
            }}
          >
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle"> {t("auth.editProfile")}</span>
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem tag="a" onClick={() => accountLogout(true)}>
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">{t("auth.logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      );
    };
    const getDetailsFromLocalStorage = (): UserLocalFilterDetail[] => {
      const getDataFromStorage: UserLocalFilterDetail[] = JSON.parse(
        localStorage.getItem("savedUserDetails")
      );
      return getDataFromStorage ? getDataFromStorage : [];
    };
    const [selectedTeam, setSelectedTeam] = useState<string>("");
    const [selectedFaculity, setSelectedFaculity] = useState<string>("");
    const CreateUserLocalStorage = (teamsId, faculityId) => {
      const savedUserDetails: UserLocalFilterDetail[] =
        getDetailsFromLocalStorage();
      const singleUserData: UserLocalFilterDetail = savedUserDetails?.find(
        (item) => item.userId == userId
      );
      if (singleUserData) {
        singleUserData.teamId = teamsId;
        singleUserData.faculityId = faculityId;
      } else {
        savedUserDetails.push({
          teamId: teamsId,
          faculityId: faculityId,
          userId: userId,
        });
      }
      localStorage.setItem(
        "savedUserDetails",
        JSON.stringify(savedUserDetails)
      );
    };
    const ClearFilters = () => {
      const savedUserDetails: UserLocalFilterDetail[] =
        getDetailsFromLocalStorage();
      const currentUserIndex: number = savedUserDetails?.findIndex(
        (item) => item.userId == userId
      );
      savedUserDetails.splice(currentUserIndex, 1);
      setSelectedFaculity("");
      setSelectedTeam("");
      localStorage.setItem(
        "savedUserDetails",
        JSON.stringify(savedUserDetails)
      );
    };
    useEffect(() => {
      if (userId) {
        const savedUserDetails: UserLocalFilterDetail[] =
          getDetailsFromLocalStorage();
        const singleUserData: UserLocalFilterDetail = savedUserDetails?.find(
          (item) => item.userId == userId
        );
        setSelectedFaculity(singleUserData?.faculityId);
        setSelectedTeam(singleUserData?.teamId);
      }
    }, [userId]);
    useEffect(() => {
      if (userId) {
        getUsersForFilters({
          ...initPaginateListPayloadGlobal,
          teamsId: selectedTeam ? selectedTeam : null,
          facultyId: selectedFaculity ? selectedFaculity : null,
        });
      }
    }, [selectedTeam, selectedFaculity]);
    useEffect(() => {
      if (!listLoading) {
        const usersList: string[] = usersForFiltersData.users?.map(
          (item: AppUser) => item.id
        );
        usersList
          ? getDashBoardReportMasterFilter(
              dashBoardReportMasterFilter,
              usersList
            )
          : null;
      }
    }, [usersForFiltersData.users]);

    const MasterFilterDropDown = () => {
      return (
        <DropdownMenu right style={{ width: "600px" }}>
          <DataLoader loading={listLoading} height="370px">
            <div className="w-100 d-flex justify-content-between">
              <div className="w-50">
                <div className="py-1 pl-1">
                  <h4>{t("nav.teams")}</h4>
                  <div className="filters-data-container filters-teams-data">
                    <PerfectScrollbar>
                      {TeamsListData.data.map((item) => (
                        <>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "90%" }}
                          >
                            <div
                              className="d-flex justify-content-between"
                              title={item.name}
                            >
                              <Checkbox
                                color="primary"
                                checked={selectedTeam == item.id ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedTeam(item.id);
                                    CreateUserLocalStorage(
                                      item.id,
                                      selectedFaculity
                                    );
                                  }
                                }}
                                icon={
                                  <Icon.Check className="vx-icon" size={16} />
                                }
                                defaultChecked={false}
                              />
                              <span className="total-users master-filter-title">
                                {item.name}
                              </span>
                            </div>
                            <span className="position-relative text-primary team-members-count">
                              {item.teamsMembers.length}
                            </span>
                            <span className="border border-light rounded-circle teams-total-members"></span>
                          </div>
                        </>
                      ))}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
              <div className="w-50">
                <div className="py-1 pl-1">
                  <div className="d-flex justify-content-between">
                    <h4>{t("nav.faculty")}</h4>
                    <Button
                      outline
                      color="secondary"
                      className="clear-master-filter mr-1"
                      onClick={() => ClearFilters()}
                    >
                      <span>{t("nav.clear")}</span>
                    </Button>
                  </div>
                  <div className="filters-data-container">
                    <PerfectScrollbar>
                      {ipFaculties?.map((item) => (
                        <>
                          <div className="d-flex justify-content-between">
                            <div
                              className="d-flex justify-content-between"
                              title={item.name}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selectedFaculity == item.id ? true : false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedFaculity(item.id);
                                    CreateUserLocalStorage(
                                      selectedTeam,
                                      item.id
                                    );
                                  }
                                }}
                                icon={
                                  <Icon.Check className="vx-icon" size={16} />
                                }
                                defaultChecked={false}
                              />
                              <span className="total-users master-filter-title">
                                {item.name}
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </DataLoader>
        </DropdownMenu>
      );
    };

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right align-items-baseline">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">5 New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.PlusSquare
                      className="font-medium-5 primary"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="primary media-heading" tag="h6">
                      You have new order!
                    </Media>
                    <p className="notification-text">
                      Are your going to meet me tonight?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      9 hours ago
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.DownloadCloud
                      className="font-medium-5 success"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="success media-heading" tag="h6">
                      99% Server load
                    </Media>
                    <p className="notification-text">
                      You got new order of goods?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      5 hours ago
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.AlertTriangle
                      className="font-medium-5 danger"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="danger media-heading" tag="h6">
                      Warning Notification
                    </Media>
                    <p className="notification-text">
                      Server has used 99% of CPU
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Today
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.CheckCircle
                      className="font-medium-5 info"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="info media-heading" tag="h6">
                      Complete the task
                    </Media>
                    <p className="notification-text">
                      One of your task is pending.
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last week
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.File className="font-medium-5 warning" size={21} />
                  </Media>
                  <Media body>
                    <Media heading className="warning media-heading" tag="h6">
                      Generate monthly report
                    </Media>
                    <p className="notification-text">
                      Reminder to generate monthly report
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last month
                    </time>
                  </small>
                </Media>
              </div>
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user" style={{ height: "34px" }}>
              <Icon.Filter size={25} />
            </span>
          </DropdownToggle>
          {MasterFilterDropDown()}
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user" style={{ height: "34px" }} title={userName}>
              <img
                src={userImage}
                alt="User Image"
                className="user-profile-style"
              />
            </span>
          </DropdownToggle>
          {UserDropdown()}
        </UncontrolledDropdown>
      </ul>
    );
  }
);
